@import '../../../../sass/foundation/mixin';

.MessageForm {
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  form {
    flex-grow: 1;
    height: 100%;
    margin-bottom: 1rem;

    @include media-all-sp {
      flex-grow: 0;
      margin-bottom: 0.4rem;
    }
  }

  .left-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;

    .Quote {
      height: auto;
      flex-grow: 0;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .MessageForm__input {
    display: flex;
    align-items: flex-start;
    height: 100%;

    @include media-all-sp {
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: 0.5rem;
    }
  }

  .right-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
  }

  .quill {
    @include media-all-pc {
      padding: 0.8rem;
      height: 100%;
      width: auto;
      display: block;
      flex-grow: 1;
      position: relative;

      &::-webkit-resizer {
        display: none;
      }
    }

    @include media-all-sp {
      padding: 0;
      line-height: 2rem;
      display: flex;
      flex-direction: column;
    }

    width: 100%;
    border: none;

    &::placeholder {
      color: #c1c1c1;
    }

    strong {
      font-weight: bold;
    }

    ol,
    ul {
      padding-left: 0.2rem;
    }

    ul li:before {
      margin-left: -2.5rem;
    }

    .ql-toolbar {
      position: absolute;
      bottom: -40px;
      left: 0;
      right: 0;

      @include media-all-sp {
        position: static;
        border-bottom: 0;
        display: flex;
        flex-wrap: wrap;
        padding: 5px;

        .ql-formats {
          margin-right: 10px;
        }
      }
    }

    .ql-container {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      border-bottom: 0;
      background: white;

      @include media-all-sp {
        position: static;
      }
    }

    .ql-editor {
      @include media-all-sp {
        min-height: 8rem;
        max-height: 16rem;
        padding: 8px;
        font-size: 16px;
        line-height: 1.3;
      }
    }
  }

  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border: 1px solid #ccc;

    .ql-tooltip {
      a {
        display: block;
      }
    }

    .ql-tooltip::before {
      content: '入力されたリンク';
    }

    .ql-tooltip[data-mode='link']::before {
      content: 'リンクを入力';
    }

    .ql-action::after {
      content: '保存';
      margin-left: 8px;
    }

    .ql-remove::before {
      content: '';
    }

    .ql-remove::after {
      content: '削除';
    }
  }

  .ql-snow .ql-editor blockquote {
    margin-bottom: 0;
    margin-top: 0;
  }

  .ql-snow .ql-picker.ql-size {
    width: 77px;
  }

  .ql-snow .ql-picker.ql-expanded .ql-picker-options {
    @include media-all-pc {
      bottom: 100%;
      top: initial;
    }

    @include media-all-sp {
      right: 0;
    }
  }

  .hidden-toolbar {
    .ql-toolbar {
      display: none;
    }
  }

  .CreateMessage__media-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;
    margin-left: 1rem;
    margin-bottom: -0.8rem;
    max-width: 17rem;

    &:empty {
      display: none;
    }

    @include media-all-pc {
      margin-top: 1rem;
    }

    @include media-all-sp {
      margin-top: 0;
      margin-left: 0;
      margin-bottom: 0;
      padding-top: 1.5rem;
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      max-width: 100%;
      height: 100%;
    }

    li {
      @include media-all-pc {
        width: 7.8rem;
        height: 7.8rem;
        margin-left: 0.3rem;
      }

      @include media-all-sp {
        width: vw-value(120);
        height: vw-value(120);
        margin-left: 0.8rem;
        margin-bottom: 0;
      }

      position: relative;
      margin-top: 0;
      margin-bottom: 0.8rem;
      margin-right: 0.3rem;
      border-radius: 0.3rem;
      outline: 1px solid #d6d6d6;

      &:last-of-type {
        cursor: pointer;
      }

      &.add-media span {
        display: block;
        width: 100%;
        height: 100%;
        background-image: url('../../../../../public/assets/img/common/btn/attach_photo.svg');
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
      }

      &.-media {
        .delete-btn {
          position: absolute;
          top: -0.7rem;
          right: -0.3rem;
          width: 1.8rem;
          height: 1.8rem;
          background-image: url('../../../../../public/assets/img/common/icon/delete.svg');
          background-size: contain;
          background-repeat: no-repeat;
          cursor: pointer;
          z-index: 50;
        }
      }

      &.-pdf {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        background-image: url('../../../../../public/assets/img/common/btn/pdf.svg');
        background-size: 2.2rem;
        background-repeat: no-repeat;
        background-position: center 40%;
        background-color: #f4f5f5;
        outline: 1px solid #d6d6d6;

        p {
          position: absolute;
          bottom: 0.6rem;
          width: 10.6rem;
          padding-right: 0.3rem;
          padding-left: 0.3rem;
          text-align: center;
          font-size: 1rem;
          letter-spacing: -0.01em;
          word-wrap: break-word;
          overflow-wrap: break-word;
          transform: scale(0.7);
          transform-origin: center center;
        }
      }

      img {
        @include media-all-pc {
          width: 7.8rem;
          height: 7.8rem;
        }

        @include media-all-sp {
          width: vw-value(120);
          height: vw-value(120);
        }

        object-fit: cover;
      }
    }
  }

  .add-media-sp {
    position: absolute;
    bottom: 0;
    left: 42%;
    transform: translate(-50%, 0%);
    z-index: 110;

    span {
      display: block;
      background-image: url('../../../../../public/assets/img/common/btn/attach_photo_sp.svg');
      background-size: contain;
      background-repeat: no-repeat;
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  .toggle-toolbar {
    position: absolute;
    bottom: 0;
    left: 25%;
    transform: translate(-50%, 0%);
    z-index: 110;

    &__icon {
      display: block;
      background-size: contain;
      background-repeat: no-repeat;
      width: 2.4rem;
      height: 2.4rem;

      &.-open {
        background-image: url('../../../../../public/assets/img/common/btn/format_open.svg');
      }

      &.-close {
        background-image: url('../../../../../public/assets/img/common/btn/format_close.svg');
      }
    }
  }

  .MessageForm__error {
    font-size: 1.5rem;
    color: #ff8888;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 3.8rem;
    line-height: inherit;

    @include media-all-sp {
      margin-top: 0;
      margin-bottom: 0.5rem;
      font-size: 1.1rem;

      &:empty {
        display: none;
      }
    }
  }
}

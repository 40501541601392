@use '../../../sass/foundation/mixin';

// ==================================================
// Component
// ==================================================

// Nav
// ==========================================================================

.Nav {
  @at-root {
    .Nav__root-pc {
      z-index: 20;
      @include mixin.media-all-sp {
        display: none;
      }
      height: 100%;
      flex-shrink: 0;
      flex-grow: 0;
      position: relative;
      width: 270px;

      // FIXME: inner だけ Fixed になっているため、スクロールすると背景だけスクロールする。
      .Nav__inner-pc {
        @media print {
          height: 100%;
        }

        width: 270px;
        background-color: #f2f2f2;
        position: fixed;
        top: 0;
        left: 0;
        padding-top: 4.4rem;
        padding-left: 1.3rem;
        padding-right: 1.3rem;
        height: 100vh;
        overflow-y: scroll;
      }

      @at-root {
        .Nav__head-pc {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-left: 1.7rem;
        }

        .Nav__logo-pc {
          width: 15.8rem;
        }

        .Nav__message-notification-num {
          @include mixin.media-all-sp {
            margin-left: 3rem;
            position: absolute;
            top: -0.2rem;
            padding-top: 0.4rem;
            font-size: 1.1rem;
          }
          margin-left: 1rem;
          width: 2rem;
          height: 2rem;
          padding-top: 0.3rem;
          border-radius: 50%;
          background-color: mixin.$color-red;
          color: #fff;
          text-align: center;
          font-size: 1.2rem;
          font-weight: 300;
        }

        .Nav__list-pc {
          padding-top: 3.8rem;
          padding-left: 1.7rem;
          padding-bottom: 3rem;

          & + .Nav__list-pc {
            border-top: 1px solid mixin.$color-mediumGray;
          }

          li {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            position: relative;
            height: 3.6rem;
            padding-left: 4.6rem;
            color: #666666;
            font-size: 1.3rem;
            font-weight: 700;
            cursor: pointer;

            &::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 3.6rem;
              height: 3.6rem;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
              z-index: 1;
            }

            &.-current {
              color: mixin.$color-red;
              cursor: default;
            }

            &.-nonDisplay {
              display: none;
            }

            & + li {
              margin-top: 3.4rem;
            }

            &.-home::before {
              background-image: url('../../../../public/assets/img/common/icon/pc_nav_home_off.svg');
            }

            &.-home.-current::before {
              background-image: url('../../../../public/assets/img/common/icon/pc_nav_home_on.svg');
            }

            &.-messages::before {
              background-image: url('../../../../public/assets/img/common/icon/pc_nav_chat_off.svg');
            }

            &.-messages.-current::before {
              background-image: url('../../../../public/assets/img/common/icon/pc_nav_chat_on.svg');
            }

            &.-schedule::before {
              background-image: url('../../../../public/assets/img/common/icon/pc_nav_cal_off.svg');
            }

            &.-schedule.-current::before {
              background-image: url('../../../../public/assets/img/common/icon/pc_nav_cal_on.svg');
            }

            &.-tasks::before {
              background-image: url('../../../../public/assets/img/common/icon/pc_nav_task_off.svg');
            }

            &.-tasks.-current::before {
              background-image: url('../../../../public/assets/img/common/icon/pc_nav_task_on.svg');
            }

            &.-checkItem::before {
              background-image: url('../../../../public/assets/img/common/icon/pc_nav_check_item_off.svg');
            }

            &.-checkItem.-current::before {
              background-image: url('../../../../public/assets/img/common/icon/pc_nav_check_item_on.svg');
            }

            &.-furniture::before {
              background-image: url('../../../../public/assets/img/common/icon/pc_nav_furniture_off.svg');
            }

            &.-furniture.-current::before {
              background-image: url('../../../../public/assets/img/common/icon/pc_nav_furniture_on.svg');
            }

            &.-files::before {
              background-image: url('../../../../public/assets/img/common/icon/pc_nav_files_off.svg');
            }

            &.-files.-current::before {
              background-image: url('../../../../public/assets/img/common/icon/pc_nav_files_on.svg');
            }

            &.-myPage::before {
              background-image: url('../../../../public/assets/img/common/icon/pc_nav_my_page_off.svg');
            }

            &.-myPage.-current::before {
              background-image: url('../../../../public/assets/img/common/icon/pc_nav_my_page_on.svg');
            }

            &.-orderSelfstyling::before {
              background-image: url('../../../../public/assets/img/common/icon/pc_nav_order_selfstyling_off.svg');
            }

            &.-orderSelfstyling.-current::before {
              background-image: url('../../../../public/assets/img/common/icon/pc_nav_order_selfstyling_on.svg');
            }

            &.-tutorial::before {
              background-image: url('../../../../public/assets/img/common/icon/pc_nav_tutorial_off.svg');
            }

            &.-tutorial.-current::before {
              background-image: url('../../../../public/assets/img/common/icon/pc_nav_tutorial_on.svg');
            }

            &.-column::before {
              background-image: url('../../../../public/assets/img/common/icon/pc_nav_column_off.svg');
            }

            &.-column.-current::before {
              background-image: url('../../../../public/assets/img/common/icon/pc_nav_column_on.svg');
            }

            &.-notificationSetting::before {
              background-image: url('../../../../public/assets/img/common/icon/pc_nav_notification_setting_off.svg');
            }

            &.-notificationSetting.-current::before {
              background-image: url('../../../../public/assets/img/common/icon/pc_nav_notification_setting_on.svg');
            }

            &.-keep::before {
              background-image: url('../../../../public/assets/img/common/icon/pc_nav_save_data_off.svg');
            }

            &.-keep.-current::before {
              background-image: url('../../../../public/assets/img/common/icon/pc_nav_save_data_on.svg');
            }

            &.-mitsuihomeclub::before {
              background-image: url('../../../../public/assets/img/common/icon/pc_nav_mitsuiclub_off.svg');
            }

            &.-mitsuihomeclub.-current::before {
              background-image: url('../../../../public/assets/img/common/icon/pc_nav_mitsuiclub_on.svg');
            }

            &.-logout::before {
              background-image: url('../../../../public/assets/img/common/icon/pc_nav_logout_off.svg');
            }

            &.-logout.-current::before {
              background-image: url('../../../../public/assets/img/common/icon/pc_nav_logout_on.svg');
            }
          }
        }

        .Nav__copyright-pc {
          display: flex;
          align-items: flex-end;
          min-height: 2rem;
          height: calc(100vh - 86.4rem);
          margin-bottom: 1.2em;
          color: #7b7b7b;
          font-size: 1.1rem;
          letter-spacing: 0.04em;
        }
      }
    }

    .Nav__root-sp {
      @include mixin.media-all-pc {
        display: none;
      }

      @media print {
        display: none;
      }

      &.-slide {
        z-index: 1001;
        flex-shrink: 0;
        flex-grow: 0;
        width: 33rem;
        height: 100vh;
        margin-left: -33rem;
        border-right: 1px solid mixin.$color-mediumGray;
        background-color: mixin.$color-lightGray;
        overflow: hidden;
        transition: margin-left 0.4s ease;

        .Nav__list-sp {
          padding-left: 2.2rem;
          border-bottom: 1px solid mixin.$color-mediumGray;
          background-color: #fff;

          li {
            display: flex;
            align-items: center;
            position: relative;
            height: 6.2rem;
            padding-left: 4.4rem;
            font-size: 1.2rem;
            z-index: 1;

            span {
              font-weight: 700;
            }

            &::before {
              content: '';
              position: absolute;
              width: 3rem;
              height: 4rem;
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center center;
            }

            &.-checkItem::before {
              left: 0.1rem;
              width: 2.7rem;
              height: 2.7rem;
              background-image: url('../../../../public/assets/img/common/icon/sp_nav_check_item.svg');
            }

            &.-column::before {
              left: 0.1rem;
              width: 2.7rem;
              height: 2.7rem;
              background-image: url('../../../../public/assets/img/common/icon/sp_nav_column.svg');
            }

            &.-myPage::before {
              left: -0.25rem;
              width: 2.8rem;
              height: 2.8rem;
              background-image: url('../../../../public/assets/img/common/icon/sp_nav_my_page.svg');
            }

            &.-orderSelfstyling::before {
              left: -0.2rem;
              width: 2.3rem;
              height: 2.8rem;
              background-image: url('../../../../public/assets/img/common/icon/sp_nav_order_selfstyling.svg');
            }

            &.-tutorial::before {
              left: 0.1rem;
              width: 2.2rem;
              height: 2.8rem;
              background-image: url('../../../../public/assets/img/common/icon/sp_nav_tutorial.svg');
            }

            &.-notificationSetting::before {
              left: 0.1rem;
              width: 2.4rem;
              height: 2.4rem;
              background-image: url('../../../../public/assets/img/common/icon/sp_nav_notification_setting.svg');
            }

            &.-keep::before {
              left: 0.3rem;
              width: 2rem;
              height: 2rem;
              background-image: url('../../../../public/assets/img/common/icon/sp_keep.svg');
            }

            &.-mitsuihomeclub::before {
              left: -0.4rem;
              width: 3.3rem;
              height: 2.6rem;
              background-image: url('../../../../public/assets/img/common/icon/sp_nav_mhc.svg');
            }

            &.-logout::before {
              left: 0.1rem;
              width: 2.6rem;
              height: 2.6rem;
              background-image: url('../../../../public/assets/img/common/icon/sp_nav_logout.svg');
            }

            & + li {
              border-top: 1px solid mixin.$color-mediumGray;
            }

            &.-nonDisplay {
              display: none;
            }
          }
        }
      }

      &.-bottom {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
        border-top: 1px solid mixin.$color-mediumGray;
        background-color: rgba(255, 255, 255, 0.9);
        transition: display 2s ease;
        transition: left 0.4s ease;
        z-index: 1000;

        .Nav__list-sp.-menu {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 0.5rem;
          padding-right: 1.2rem;
          padding-left: 1.2rem;
          padding-bottom: 0.7rem;

          li {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: calc(100% / 4); // li length
            height: 3.6rem;
            margin-top: 0.2rem;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;

            &.-schedule {
              background-image: url('../../../../public/assets/img/common/icon/sp_nav_cal_off.svg');
            }

            &.-schedule.-current {
              background-image: url('../../../../public/assets/img/common/icon/sp_nav_cal_on.svg');
            }

            &.-tasks {
              background-image: url('../../../../public/assets/img/common/icon/sp_nav_task_off.svg');
            }

            &.-tasks.-current {
              background-image: url('../../../../public/assets/img/common/icon/sp_nav_task_on.svg');
            }

            &.-furniture {
              background-image: url('../../../../public/assets/img/common/icon/sp_nav_furniture_off.svg');
            }

            &.-furniture.-current {
              background-image: url('../../../../public/assets/img/common/icon/sp_nav_furniture_on.svg');
            }

            &.-files {
              background-image: url('../../../../public/assets/img/common/icon/sp_nav_files_off.svg');
            }

            &.-files.-current {
              background-image: url('../../../../public/assets/img/common/icon/sp_nav_files_on.svg');
            }

            &.-messages {
              background-image: url('../../../../public/assets/img/common/icon/sp_nav_chat_off.svg');
            }

            &.-messages.-current {
              background-image: url('../../../../public/assets/img/common/icon/sp_nav_chat_on.svg');
            }

            a {
              justify-content: center;
              align-items: center;
              display: block;
              width: 60%;
              height: 100%;
            }

            &.-nonDisplay {
              display: none;
            }
          }
        }
      }
    }

    .mh-copyright {
      @include mixin.media-all-pc {
        align-items: flex-start;
        width: 100%;
        min-height: 16rem;
        padding-top: 3rem;
        padding-bottom: 1.4rem;
        border-top: 1px solid mixin.$color-mediumGray;
      }

      @include mixin.media-all-sp {
        align-items: flex-start;
        width: 94%;
        height: calc(100% - 42rem);
        padding-bottom: mixin.vw-value(50);
      }

      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-right: auto;
      margin-left: auto;

      .mh-copyright__privacy {
        display: flex;
        flex-direction: column;
        padding-bottom: 1.5rem;
        @include mixin.media-all-sp {
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          padding: 1.5rem;
        }
        p {
          margin-top: 0;
          padding-bottom: 1.5rem;
        }
      }

      .mh-copyright__privacy-row {
        font-size: 1rem;
        padding-bottom: 0.3rem;
        &:hover {
          font-size: 1.2rem;
        }
        @include mixin.media-all-sp {
          font-size: 1.2rem;
          font-weight: 700;
          padding-bottom: 1.5rem;
        }
      }

      .mh-copyright__logo {
        display: flex;
        flex-direction: column;

        .mh-copyright__logo-corporate {
          @include mixin.media-all-pc {
            width: 60%;
            margin-bottom: 1rem;
          }

          @include mixin.media-all-sp {
            width: 48%;
            margin-left: 5%;
            margin-bottom: 1rem;
          }
        }

        .mh-copyright__logo-group {
          @include mixin.media-all-pc {
            width: 70%;
          }

          @include mixin.media-all-sp {
            width: 55%;
            margin-left: 5%;
          }
        }
      }

      p {
        @include mixin.media-all-pc {
          margin-top: 1.2rem;
          text-align: left;
          font-size: 1rem;
        }

        @include mixin.media-all-sp {
          margin-top: mixin.vw-value(30);
          text-align: left;
          font-size: mixin.vw-value(24);
          margin-left: 5%;
        }

        color: #606060;
        letter-spacing: 0.05em;
      }
    }
  }
}

@use '../../../sass/foundation/mixin';

// ==================================================
// Component
// ==================================================

// Login
// ==========================================================================

.Login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  @include mixin.media-all-sp {
    // width: 40%;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .Login__header {
    display: flex;

    .Login__header__brand-logo-left {
      display: flex;
      justify-content: flex-start;
      margin-right: auto;
      margin-left: auto;
      @include mixin.media-all-sp {
        width: 40%;
      }
    }

    .Login__header__brand-logo-right {
      display: flex;
      justify-content: flex-end;
      margin-right: auto;
      margin-left: auto;
      @include mixin.media-all-sp {
        width: 40%;
      }
    }
  }

  h1 {
    width: 24rem;
    margin-bottom: 6.6rem;
  }

  h2 {
    font-size: 2.1rem;
    font-weight: 600;
  }

  .Login__redirect {
    margin-bottom: 3rem;
    font-size: 1.2rem;
    color: #a6192e;
    text-align: center;
    line-height: 1.6;
  }

  .Login__note {
    margin-top: 3rem;
    font-size: 1.2rem;
    color: #a6192e;
    text-align: center;
    line-height: 1.6;
  }

  .Login__button-container {
    .Login__button {
      display: block;
      width: 31.4rem;
      margin-top: 3.2rem;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 0.8rem;
      padding-top: 2.8rem;
      padding-bottom: 2.6rem;
      font-size: 1.7rem;
      background-color: #a6192e;
      color: #fff;
      text-align: center;
      font-weight: 600;

      &.-login {
        width: 23rem;
        margin-top: 3.8rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
      }

      & + .LoginForm__button {
        margin-top: 3.4rem;
      }

      &:hover {
        background-color: #a6192e;
        opacity: 0.8;
      }
    }

    span {
      display: block;
      font-size: 1.2rem;
      text-align: center;
      line-height: 1.6;
      margin-bottom: 6rem;

      @include mixin.media-all-sp {
        margin-bottom: 3rem;
      }
    }
  }

  .Login__copyright {
    margin-top: 3rem;
    font-size: 1.2rem;
    text-align: center;
    line-height: 1.6;

    @include mixin.media-all-sp {
      margin-top: 1rem;
    }

    .Login__copyright__privacy {
      margin-bottom: 1rem;

      @include mixin.media-all-sp {
        margin-bottom: 0rem;
      }

      .Login__copyright__privacy-row {
        margin-right: 1.2rem;
        white-space: nowrap;
      }
    }
  }

  .Login__brand-logo-footer {
    display: flex;
    // justify-content: space-between;
    // align-items: center;
    margin-top: 3rem;
    margin-right: auto;
    margin-left: auto;
    @include mixin.media-all-sp {
      margin-top: 1rem;
    }

    .mh-copyright__logo-corporate {
      width: 160px;
    }
    .mh-copyright__logo-group {
      width: 200px;
    }
    @include mixin.media-all-sp {
      justify-content: center;
      .mh-copyright__logo-corporate {
        width: 36%;
      }
      .mh-copyright__logo-group {
        width: 45%;
      }
    }
  }

  // システムメッセージをログイン選択画面で表示する場合は必要になる↓

  // .Message__item {
  //   width: 90%;
  //   max-width: 57rem;
  //   border: solid 1px #ff8888;
  //   border-radius: 0.6rem;
  //   margin-bottom: 4rem;

  //   .Message__item-wrap {
  //     margin: 1.3rem;
  //     color: #3d3d3d;
  //     margin-bottom: 2.4rem;

  //     .Message__item-title {
  //       margin-bottom: 1.3rem;
  //       font-size: 1.2rem;
  //       font-weight: bold;
  //       text-align: center;
  //     }

  //     .Message__item-message {
  //       white-space: pre-wrap;
  //       font-size: 1.05rem;
  //       line-height: 1.4rem;
  //     }
  //   }
  // }
  // .Message__item :last-child {
  //   margin-bottom: 1.3rem;
  // }
}

// システムメッセージをログイン選択画面で表示する場合は必要になる↓

// .Login_message {
//   height: auto;
//   margin-top: 10rem;
//   padding-bottom: 10rem;

//   h1 {
//     margin-bottom: 4rem;
//   }
// }

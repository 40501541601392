@import '../../../sass/foundation/mixin';

// ==================================================
// Project
// ==================================================

// Attention
// ==========================================================================

.Attention {
  margin: 0 auto;
  padding-top: 60px;
  max-width: 1200px;

  &__back {
    display: flex;
    justify-content: flex-end;
    // padding-right: 5%;
    padding-bottom: 20px;
    margin: 0 20px;
    &-link {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 7rem;
      height: 4rem;
      border: solid 2px;
      border-radius: 10%;
      border-color: #d0d0d0;
      background-color: #f5f5f5;
      font-size: 1.5rem;
      font-weight: 700;

      &:hover {
        color: #444444;
        border-color: #b0b0b0;
        background-color: #e5e5e5;
      }
    }
  }

  &__title {
    margin: 0 20px;

    h1 {
      font-size: 3.6rem;
      font-weight: 700;

      @include media-all-sp {
        font-size: 2.8rem;
      }
    }

    p {
      font-size: 1.5rem;
      margin-top: 30px;
      margin-bottom: 60px;

      @include media-all-sp {
        font-size: 1.2rem;
      }
    }
  }

  &__content {
    margin: 0 auto;
    max-width: 1000px;
  }

  &__block {
    margin-bottom: 60px;
    font-size: 1.6rem;

    @include media-all-sp {
      font-size: 1.3rem;
    }

    h2 {
      margin-bottom: 30px;
      padding-left: 20px;
      background-color: #f5f5f5;
      line-height: 4rem;
      font-weight: 700;
    }

    &-content {
      padding: 0 20px;
    }

    ul {
      padding-left: 20px;
      list-style-type: disc;

      li {
        margin-top: 10px;
      }
    }

    a {
      text-decoration: underline;
      transition: none;
      &:hover {
        opacity: 0.6;
        text-decoration: none;
      }
    }
  }

  &__sub-block {
    margin: 30px 0;

    h3 {
      margin-bottom: 10px;
      font-weight: 700;
    }
  }
}

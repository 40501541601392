@use '../../../sass/foundation/mixin';

// ==================================================
// Component
// ==================================================

// Media
// ==========================================================================
.Media {
  @at-root {
    .Media__box {
      h2 {
        @include mixin.media-all-pc {
          font-size: 2rem;
        }

        @include mixin.media-all-sp {
          text-align: center;
          font-size: 1.6rem;
        }

        font-weight: 700;
        line-height: 1.6;
      }

      .check-list {
        @include mixin.media-all-pc {
        }
        @include mixin.media-all-sp {
          margin-top: mixin.vw-value(20);
        }
        display: flex;

        li {
          @include mixin.media-all-pc {
            margin-top: 2rem;
          }

          @include mixin.media-all-sp {
            font-size: 1rem;
          }

          display: flex;
          justify-content: flex-start;
          align-items: center;
          position: relative;

          &::before {
            @include mixin.media-all-pc {
              width: 1.4rem;
              height: 1.4rem;
              margin-right: 0.5rem;
            }

            @include mixin.media-all-sp {
              width: 1rem;
              height: 1rem;
              margin-right: 0.3rem;
            }

            content: '';
            display: block;
            flex-shrink: 0;
            border-radius: 3px;
          }

          & + li {
            @include mixin.media-all-pc {
              margin-left: 1.5rem;
            }
            @include mixin.media-all-sp {
              margin-left: 0.5rem;
            }
          }

          &.-red {
            &::before {
              border: 1px solid mixin.$color-check-red;
            }
          }

          &.-yellow {
            &::before {
              border: 1px solid mixin.$color-check-yellow;
            }
          }

          &.-green {
            &::before {
              border: 1px solid mixin.$color-check-green;
            }
          }

          &.-blue {
            &::before {
              border: 1px solid mixin.$color-check-blue;
            }
          }

          &.-purple {
            &::before {
              border: 1px solid mixin.$color-check-purple;
            }
          }

          &.-checked {
            &::before {
              @include mixin.media-all-pc {
                width: 1.6rem;
                height: 1.6rem;
              }

              @include mixin.media-all-sp {
                width: 1.2rem;
                height: 1.2rem;
              }

              border: none;
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }

            &.-red {
              &::before {
                background-image: url('../../../../public//assets/img/appointments/agendas/columns/approve_check_red.svg');
              }
            }

            &.-yellow {
              &::before {
                background-image: url('../../../../public//assets/img/appointments/agendas/columns/approve_check_yellow.svg');
              }
            }

            &.-green {
              &::before {
                background-image: url('../../../../public//assets/img/appointments/agendas/columns/approve_check_green.svg');
              }
            }

            &.-blue {
              &::before {
                background-image: url('../../../../public//assets/img/appointments/agendas/columns/approve_check_blue.svg');
              }
            }

            &.-purple {
              &::before {
                background-image: url('../../../../public//assets/img/appointments/agendas/columns/approve_check_purple.svg');
              }
            }
          }

          &.-ignored {
            &::before {
              @include mixin.media-all-pc {
                width: 1.6rem;
                height: 1.6rem;
              }

              @include mixin.media-all-sp {
                width: 1.2rem;
                height: 1.2rem;
              }

              border: none;
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }

            &.-red {
              &::before {
                background-image: url('../../../../public//assets/img/appointments/agendas/columns/approve_check_ignored_red.svg');
              }
            }

            &.-yellow {
              &::before {
                background-image: url('../../../../public//assets/img/appointments/agendas/columns/approve_check_ignored_yellow.svg');
              }
            }

            &.-green {
              &::before {
                background-image: url('../../../../public//assets/img/appointments/agendas/columns/approve_check_ignored_green.svg');
              }
            }

            &.-blue {
              &::before {
                background-image: url('../../../../public//assets/img/appointments/agendas/columns/approve_check_ignored_blue.svg');
              }
            }

            &.-purple {
              &::before {
                background-image: url('../../../../public//assets/img/appointments/agendas/columns/approve_check_ignored_purple.svg');
              }
            }
          }

          & > span {
            @include mixin.media-all-pc {
              max-width: 12rem;
            }
            @include mixin.media-all-sp {
              max-width: 14vw;
            }

            letter-spacing: 0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -webkit-text-overflow: ellipsis; /* Safari */
            -o-text-overflow: ellipsis; /* Opera */
          }
        }
      }
    }
  }
}

.Media__root-agenda {
  @include mixin.media-all-pc {
  }
  @include mixin.media-all-sp {
    height: 100%;
    padding-bottom: 3rem;
  }

  @at-root {
    .Media__box-agenda {
      @include mixin.media-all-pc {
        padding-bottom: 8.2rem;
      }

      .Media__box-inner-agenda-download {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        font-size: 1.5rem;
        gap: 10px;
        position: absolute;
        right: 15px;
        top: 100px;

        .Media__download-all-columns {
          display: flex;
          align-items: center;
          gap: 10px;
          font-weight: bold;
          border: 1px solid mixin.$color-red;
          border-radius: 5px;
          padding: 10px 15px;
          background-color: mixin.$color-red;
          color: #fff;
          margin: 0 auto;

          .Media__download-all-columns-icon {
            width: 20px;
            height: 20px;
          }
        }
        .Media__download-all-columns-note {
          position: relative;
          right: auto;
          top: auto;
          font-weight: bold;
          color: gray;
        }

        @include mixin.media-all-sp {
          font-size: 1.2rem;
          gap: 5px;
          right: 0;
          top: 80px;
        }

        .Media__download-all-columns {
          @include mixin.media-all-sp {
            padding: 8px 12px;
            gap: 5px;

            .Media__download-all-columns-icon {
              width: 16px;
              height: 16px;
            }
          }
        }

        .Media__download-all-columns-note {
          @include mixin.media-all-sp {
            font-size: 1rem;
          }
        }
      }

      .Media__box-inner-agenda {
        @include mixin.media-all-pc {
          width: 62rem;
          margin-right: auto;
          margin-left: auto;
        }

        @include mixin.media-all-sp {
          width: mixin.vw-value(675);
          margin-right: auto;
          margin-left: auto;
          padding-bottom: mixin.vw-value(140);
        }
      }

      h2 {
        @include mixin.media-all-pc {
          margin-top: 2.2rem;
        }

        @include mixin.media-all-sp {
          margin-top: 1.5rem;
        }

        word-wrap: break-word;
        overflow-wrap: break-word;
      }
    }

    .Media__list-agenda {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid mixin.$color-mediumGray;

      @include mixin.media-all-pc {
        padding-bottom: 2.5rem;
      }

      @include mixin.media-all-sp {
        justify-content: space-between;
        padding-bottom: mixin.vw-value(43);
      }

      li {
        @include mixin.media-all-pc {
          width: 14rem;
          height: 12.5rem;
          margin-top: 1.6rem;
          margin-right: 2rem;
        }

        @include mixin.media-all-sp {
          width: mixin.vw-value(328);
          height: mixin.vw-value(290);
          margin-top: mixin.vw-value(21);
        }

        position: relative;
        border: 1px solid #c1c1c1;
        border-radius: 5px;
        overflow: hidden;

        &.-flag-bg {
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.8);
            z-index: 30;
            pointer-events: none;
          }
        }

        &.-flag-single::before {
          @include mixin.media-all-pc {
            background-size: 3rem;
          }

          @include mixin.media-all-sp {
            background-size: 4.4rem;
          }
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: url('../../../../public//assets/img/common/icon/flag_on.svg');
          background-repeat: no-repeat;
          background-position: center center;
          background-color: rgba(255, 255, 255, 0.8);
          z-index: 30;
          pointer-events: none;
        }

        &:nth-child(4n) {
          @include mixin.media-all-pc {
            margin-right: 0;
          }
        }

        .check-flag {
          position: absolute;
          width: 2rem;
          top: 50%;
          transform: translateY(-70%);
          pointer-events: none;
          z-index: 50;

          &:nth-of-type(1) {
            @include mixin.media-all-pc {
              left: 0.9rem;
            }
            @include mixin.media-all-sp {
              left: 2.6vw;
            }
          }
          &:nth-of-type(2) {
            @include mixin.media-all-pc {
              left: 3.4rem;
            }
            @include mixin.media-all-sp {
              left: 11vw;
            }
          }
          &:nth-of-type(3) {
            @include mixin.media-all-pc {
              left: 5.9rem;
            }
            @include mixin.media-all-sp {
              left: 19vw;
            }
          }
          &:nth-of-type(4) {
            @include mixin.media-all-pc {
              left: 8.4rem;
            }
            @include mixin.media-all-sp {
              left: 27vw;
            }
          }
          &:nth-of-type(5) {
            @include mixin.media-all-pc {
              left: 10.9rem;
            }
            @include mixin.media-all-sp {
              left: 35vw;
            }
          }
        }

        .text-name {
          @include mixin.media-all-pc {
            padding-top: 0.4rem;
            padding-bottom: 0.3rem;
            font-size: 1.2rem;
          }

          @include mixin.media-all-sp {
            padding-top: 0.5rem;
            padding-bottom: 0.4rem;
            font-size: mixin.vw-value(24);
          }

          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          padding-right: 0.6rem;
          padding-left: 0.6rem;
          background-color: rgba(255, 255, 255, 0.8);
          text-align: center;
          font-weight: 700;
          line-height: 1.4;
          letter-spacing: -0.01em;
          word-break: break-all;
        }

        .text-number {
          @include mixin.media-all-pc {
            font-size: 2rem;
            top: -3rem;
          }

          @include mixin.media-all-sp {
            font-size: mixin.vw-value(46);
            top: -3.5rem;
          }

          position: absolute;
          right: 0.6rem;
          color: #fff;
          filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.5));
        }
      }
    }
  }
}

.Media__root-column {
  @include mixin.media-all-sp {
    height: 100%;
  }

  @at-root {
    .Media__box-column {
      @include mixin.media-all-pc {
        padding-bottom: 2rem;
        background-color: #fff;
      }
      @include mixin.media-all-sp {
        padding-bottom: mixin.vw-value(20);
      }

      .Media__box-inner-column {
        @include mixin.media-all-pc {
          max-width: 93rem;
          width: 90%;
          margin-right: auto;
          margin-left: auto;
        }
        @include mixin.media-all-sp {
          padding-bottom: 4rem;
        }
      }
    }

    .Media__kv-column {
      @include mixin.media-all-pc {
        width: 100%;
        height: 33rem;
      }

      @include mixin.media-all-sp {
        width: 100%;
        height: mixin.vw-value(368);
      }

      position: relative;
      overflow: hidden;

      img {
        @include mixin.media-all-pc {
          height: 33rem;
        }

        @include mixin.media-all-sp {
          height: mixin.vw-value(368);
        }

        width: 100%;
        object-fit: cover;
      }

      .Media__kv-number {
        @include mixin.media-all-pc {
          bottom: 2rem;
          right: 20vw;
          font-size: 5.4rem;
        }

        @include mixin.media-all-sp {
          bottom: mixin.vw-value(12);
          right: mixin.vw-value(37);
          font-size: mixin.vw-value(105);
        }

        position: absolute;
        color: #fff;
        filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.4));
      }
    }

    .Media__text {
      width: 90%;
      padding: 2rem;
      font-size: 1.5rem;
      display: block;
      margin: auto;
      line-height: 2.2rem;

      @include mixin.media-all-sp {
        width: 95%;
        padding: 1rem;
      }
    }

    .Media__list-column {
      border-top: 1px solid mixin.$color-mediumGray;
      border-bottom: 1px solid mixin.$color-mediumGray;
      background-color: #fff;
      font-size: 1.1rem;

      &.-ignored {
        background-color: mixin.$color-lightGray;

        & > li {
          color: #bcbcbc;
          cursor: default;
          pointer-events: none;

          &.-checked {
            color: #bcbcbc;

            &::before {
              content: none;
            }
          }
        }
      }

      & > li {
        @include mixin.media-all-sp {
          margin-left: mixin.vw-value(20);
        }

        display: flex;
        align-items: center;
        position: relative;
        border-bottom: 1px solid mixin.$color-mediumGray;

        &::after {
          @include mixin.media-all-pc {
            right: 1.9rem;
            width: 0.8rem;
            height: 1.6rem;
          }
          @include mixin.media-all-sp {
            right: mixin.vw-value(22);
            width: mixin.vw-value(12);
            height: mixin.vw-value(24);
          }

          content: '';
          position: absolute;
          background-image: url('../../../../public//assets/img/common/icon/arrow_right_02.svg');
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
        }
        & > a {
          & > span {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            position: relative;
          }
        }

        &.-checked {
          & > a {
            & > span {
              color: #bebebe;

              &::before {
                @include mixin.media-all-pc {
                  left: 0;
                  width: 4.6rem;
                  height: 4.6rem;
                }

                @include mixin.media-all-sp {
                  left: 0;
                  width: mixin.vw-value(64);
                  height: mixin.vw-value(64);
                }

                content: '';
                position: absolute;
                background-image: url('../../../../public//assets/img/appointments/agendas/articles/icon/check_circle.svg');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
              }
            }
          }
        }

        &:last-child {
          border-bottom: none;
        }

        & > a {
          @include mixin.media-all-pc {
            padding-top: 1.8rem;
            padding-bottom: 1rem;
          }
          @include mixin.media-all-sp {
            padding-top: mixin.vw-value(18);
            padding-bottom: mixin.vw-value(15);
          }

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          width: 100%;

          & > span {
            @include mixin.media-all-pc {
              padding-left: 3.2rem;
              font-size: 1.5rem;
            }

            @include mixin.media-all-sp {
              padding-left: mixin.vw-value(41);
              font-size: mixin.vw-value(24);
            }

            width: 90%;
            line-height: 1.6;
            font-weight: 700;
            word-wrap: break-word;
            overflow-wrap: break-word;
          }
        }
      }
    }
  }
}

.Media__root-files {
  @include mixin.media-all-pc {
    padding-bottom: 8.2rem;
  }
  @include mixin.media-all-sp {
    height: 100%;
  }

  @at-root {
    .Media__box-files {
      @include mixin.media-all-pc {
        padding-top: 2.2rem;
        padding-bottom: 2.5rem;
      }

      @include mixin.media-all-sp {
        width: mixin.vw-value(675);
        margin-top: 1.5rem;
        margin-right: auto;
        margin-left: auto;
        padding-bottom: 42vw;
      }

      .Media__box-inner-files {
        @include mixin.media-all-pc {
          width: 62.4rem;
          margin-right: auto;
          margin-left: auto;
        }
      }
    }

    .Media__list-files {
      display: flex;
      flex-wrap: wrap;

      li {
        @include mixin.media-all-pc {
          width: 10rem;
          margin-top: 1rem;
          margin-right: 3.1rem;
        }

        @include mixin.media-all-sp {
          width: mixin.vw-value(200);
          margin-top: mixin.vw-value(14);
          margin-right: mixin.vw-value(36);
        }

        &:nth-child(5n) {
          @include mixin.media-all-pc {
            margin-right: 0;
          }
        }

        &:nth-child(3n) {
          @include mixin.media-all-sp {
            margin-right: 0;
          }
        }

        img {
          @include mixin.media-all-pc {
            width: 10rem;
            height: 10rem;
          }

          @include mixin.media-all-sp {
            width: mixin.vw-value(200);
            height: mixin.vw-value(200);
          }

          border: 1px solid #c1c1c1;
          object-fit: cover;
        }

        .FileView__item-name {
          @include mixin.media-all-pc {
            margin-top: 0.2rem;
            padding-top: 0.4rem;
            padding-bottom: 0.3rem;
            font-size: 1rem;
          }

          @include mixin.media-all-sp {
            padding-top: 0.5rem;
            padding-bottom: 0.4rem;
            font-size: mixin.vw-value(19);
          }

          display: block;
          width: 100%;
          line-height: 1.5;
          letter-spacing: -0.01em;
          word-break: break-all;
        }

        .FileView__item-badge {
          margin-left: 0.2rem;
          color: #ff0000;
        }
      }
    }

    .Media__collection {
      ul {
        border-bottom: 1px solid mixin.$color-mediumGray;

        @include mixin.media-all-pc {
          padding-bottom: 2.5rem;
          margin-bottom: 2.2rem;
        }

        @include mixin.media-all-sp {
          padding-bottom: mixin.vw-value(43);
          margin-bottom: 1.5rem;
        }
      }
    }
  }
}
